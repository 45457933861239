<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                <h4>Pilih salah satu Jenis Pembelajaran</h4>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-6 d-flex align-items-stretch flex-column">
                    <div class="card bg-light d-flex flex-fill">
                        <div class="card-header text-muted border-bottom-0">
                        </div>
                        <div class="card-body pt-0">
                        <div class="row">
                            <div class="col-12">
                            <h2 class="lead"><b>OFFLINE</b></h2>
                            <p class="text-muted text-sm"><b>Belajar Tatap Muka </b> di LBB Taruna Cendekia </p>
                            <p v-if="ujian.qty_offline < 1">Belum ada soal latihan</p>                            
                            <p v-if="ujian.qty_offline >= 1">{{ ujian.qty_offline }} Soal Latihan</p>
                            </div>
                        </div>
                        </div>
                        <div class="card-footer">
                        <div class="text-right" v-if="ujian.qty_offline < 1"></div>
                        <div class="text-right" v-if="ujian.qty_offline >= 1">
                            <router-link :to="{
                                name: 'Daftar Mapel Soal',
                                params: { filter: { jenis: 'offline', id: ujian.id }},
                            }" class="btn btn-sm btn-primary">
                            <i class="fas fa-arrow-right"></i> Mulai
                            </router-link>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-6 d-flex align-items-stretch flex-column">
                    <div class="card bg-light d-flex flex-fill">
                        <div class="card-header text-muted border-bottom-0">
                        </div>
                        <div class="card-body pt-0">
                        <div class="row">
                            <div class="col-12">
                            <h2 class="lead"><b>ONLINE</b></h2>
                            <p class="text-muted text-sm"><b>Belajar Mandiri </b> di Rumah untuk pengayaan dan pemantapan </p>
                            <p v-if="ujian.qty_online < 1">Belum ada soal latihan</p>
                            <p v-if="ujian.qty_online >= 1">{{ ujian.qty_online }} Soal Latihan</p>
                            </div>
                        </div>
                        </div>
                        <div class="card-footer">
                        <div class="text-right" v-if="ujian.qty_online < 1"></div>
                        <div class="text-right" v-if="ujian.qty_online >= 1">
                            <router-link :to="{
                                name: 'Daftar Mapel Soal',
                                params: { filter: { jenis: 'online', id: ujian.id }},
                            }" class="btn btn-sm btn-primary">
                            <i class="fas fa-arrow-right"></i> Mulai
                            </router-link>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </section>
</template>
<script>
import { authFetch } from "@/libs/hxcore";
export default {
    name: 'Soal',
    data: () => ({
        ujian: {},
    }),
    created: function () {
        // this.loadSoal();
    },
    methods: {
        loadSoal(){
            authFetch("/tentor/ujian/latihan").then((res) => {
                res.json().then((json) => {
                if (json.success) {
                    this.ujian = json.data;
                }
                });
            });
        }
    },
    mounted(){
        this.loadSoal();
    },
}
</script>
<style type="text/css" scoped>
</style>